  <!--
 * @Descripttion: 微客工具
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-11-08 14:52:13
-->
<template>
  <wk-header ref="header" :currentIndex="3"></wk-header>
  <el-main class="min-main">
    <el-row class="h100" :gutter="40">
      <el-col :span="3" class="left-row h100" id="leftElRow" ref="leftElRow">
        <div class="left-user">
          <img v-if="userInfo.portrait" :src="userInfo.portrait" :alt="userInfo.name" :title="userInfo.name" class=" avatar user-img">
          <img v-else src="./../../assets/img/demo.png" class="avatar">
          <p class="gray">{{userInfo.name}}</p>
        </div>
        <div class="left-tool-type">
          <p
            v-for="item in toolTypes"
            :key="item.id"
            :class="toolTypeIndex == item.id ?  'left-icon active' : 'left-icon'"
            :ref="item.id"
            @click="handleToolType(item)"
          >
            <i v-show="toolTypeIndex == item.id" :style="{ backgroundImage:'url(https://hiweke.bidsoon.cn/sys/common/static/'+ item.iconActi +')'}"></i>
            <i v-show="toolTypeIndex != item.id" :style="{ backgroundImage:'url(https://hiweke.bidsoon.cn/sys/common/static/'+ item.icon +')'}"></i>
            {{ item.name }}
          </p>

        </div>
        <div class="tips-scroll" :class="{'show-arrow': hasScroll && showArrow}" @click="goScroll">
            <arrow-down class="tips-arrow"></arrow-down>
          </div>
      </el-col>
      <el-col :span="18" :offset="3">
        <div class="industry">
          <h3>行业</h3>
          <label
            :class="toolIndustryIndex == item.id ? 'active' : ''"
            v-for="item in toolIndustries"
            :key="item.id"
            @click="handleToolIndustry(item)"
          >
            {{ item.name }}
          </label>
        </div>
        <div class="tool-order" style="margin-top:20px">
          <div>
            <h3>排序</h3>
            <label
              :class="orderIndex == item.id ? 'active' : ''"
              v-for="item in toolOrder"
              :key="item.id"
              @click="handleToolOrder(item)"
            >
              {{ item.name }}
            </label>
          </div>
          <p>
            <fold @click="currentType = '1'" :class="{'normal-svg': true, 'active': currentType == '1'}"/>
            <menu1 @click="currentType = '2'" :class="{'normal-svg': true, 'active': currentType == '2'}" />
          </p>
        </div>

        <el-row :gutter="20" style="padding-bottom:30px">
          <template v-if="currentType == '1'">
          <el-col :span="8" v-for="item in toolsData" :key="item" >
            <div class="tool-vertical-li" @click="handleDetail(item)">
              <div class="tool-li-title">
                <img class="tool-logo" v-if="item.propagatePic" :src="item.propagatePic">
								<img class="tool-logo" v-else>
                <div class="tool-title-content">
                  <h4 class="el-ellipsis">{{item.toolName}}</h4>
                  <p class="industry el-ellipsis" style="display:inherit">{{item.industryLabel}}</p>
                </div>
              </div>
              <div class="tool-desc el-ellipsis--l2">
                <p>{{item.description}}</p>
              </div>
            </div>
          </el-col>
          </template>
          <template v-else>
          <el-col :span="6" v-for="item in toolsData" :key="item">
            <div class="tool-vertical-li"  @click="handleDetail(item)">
              <h3 class="el-ellipsis">{{item.toolName}}</h3>
              <img class="tool-pic" v-if="item.propagateBigPic" :src="item.propagateBigPic" >
              <img class="tool-pic" v-else-if="item.propagatePic" :src="item.propagatePic" >
              <img class="tool-pic" v-else src="./../../assets/img/demo.png" >
              <div class="tool-desc el-ellipsis--l2">
                <p>{{item.description}}</p>
              </div>
              <p class="root-acti mt10" style="padding:0 15px">{{item.industryLabel}}</p>
            </div>
          </el-col>
          </template>
        </el-row>

      </el-col>
    </el-row>

  </el-main>
  <wk-footer></wk-footer>
</template>

<script>
import WkHeader  from '@/components/WKHeader'
import WkFooter from '@/components/WKFooter'
import {getAction,postAction} from '@/utils/http'

export default {
  name: 'Tools',
  components: {
    WkHeader,
    WkFooter
  },
  data() {
    return {
      // 工具类别
      toolTypes: [],
      toolTypeIndex: '',
      // 行业类别
      toolIndustries: [],
      toolIndustryIndex: '1',
      // 排序
      toolOrder: [
        {id: '1', name: '热门工具'},
        {id: '2', name: '下载最多'},
        {id: '3', name: '浏览最多'},
      ],
      orderIndex: '1',
      /** 当前的样式类别 */
      currentType: '1',
			//当前分类id
			sortId:'',
			//当前行业id
			industryId:'',
			//工具集合
			toolsData:[],
      toolsTotal: 0,
			//当前页码
			page:1,
			pageSize:12,
			imgUrl:"https://cshiweke.bidsoon.cn/sys/common/static/",
      loading:false,//加载中
      params:{
        newFlag:'',
        hotFlag:'',
        mostReadFlag:''
      },
      userInfo:{
        id:'',
        openid:'',
        unionid:'',
        portrait:'',
        sex:'',
        name:''
      },
      urls:{
        getToolsIndustrySort:'/wbpt/tools/home/getToolsIndustrySort'
      },
      industryMap: new Map(),
      showArrow: false,
      hasScroll: false
    }
  },

  beforeMount() {
    this.$nextTick(() => {
      window.addEventListener('scroll', this.initScroll, true)
      document.getElementById('leftElRow').addEventListener('scroll', this.leftElRowScroll, true)
    })

  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.initScroll, true)
    document.getElementById('leftElRow').removeEventListener('scroll', this.leftElRowScroll, true)
  },
  created(){
    if(this.$ls.get("wikeHome_current_userInfo")){
      this.userInfo = this.$ls.get("wikeHome_current_userInfo");
      this.$ls.set("wikeHome_current_userInfo",this.userInfo,28*60*60*1000);//设置有效时间 28天
    }
  },
	mounted(){
    this.params.newFlag = '1';
		this.getToolsIndustry()
	},

  methods: {
		//查询工具集合
		getToolsCollection:function(){
      this.loading = true;
			// this.toolsData = []
			postAction("/wbpt/tools/home/getToolsCollection",{
				sortId:this.sortId,
				industryId:this.industryId,
				page:this.page,
				pageSize:this.pageSize,
        newFlag: this.params.newFlag,
        hotFlag: this.params.hotFlag,
        mostReadFlag: this.params.mostReadFlag
			}).then(res=>{
				if(res.rows){
          this.toolsTotal = res.total;
					for(let ii=0;ii<res.rows.length;ii++){
						let row = res.rows[ii]
						//处理行业
						if(row.industrys){
							let industryLabel = ''
							for(let kk=0;kk<row.industrys.length;kk++){
								industryLabel += row.industrys[kk].labelName + ' '
							}
							row.industryLabel = industryLabel.substr(0,industryLabel.length - 1)
						}
						this.toolsData.push(row);
					}
				}
			}).finally(()=>{
        this.loading = false;
      });
		},

		//检索行业分类
		getToolsIndustry:function(){
			this.toolIndustries = []

			getAction("/wbpt/tools/home/getToolsIndustry").then(res=>{
				if(res.rows){
					for(let ii=0;ii<res.rows.length;ii++){
            if(ii==0){
              this.toolIndustryIndex = res.rows[ii].id;
              this.industryId = res.rows[ii].id;
            }
						this.toolIndustries.push({
							id:res.rows[ii].id,
							name:res.rows[ii].labelName
						})
					}
				}
			}).finally(()=>{

        if(this.$route.query.industryId && this.$route.query.industryId != '') {
          this.industryId =  this.$route.query.industryId
          this.toolIndustryIndex = this.industryId
          this.sortId = this.$route.query.sortId
          this.toolTypeIndex = this.sortId;
          if(this.sortId) {
            this.$nextTick( () => {
              setTimeout(() => {
                document.getElementById('leftElRow').scrollTop = this.$refs[this.sortId].offsetTop
              }, 50);
            })
          }

        }
        this.isExistLeftScroll()
        this.getToolsCollection();
        this.getToolsIndustrySort();
      });
		},
    //new 查询工具行业--分类
    getToolsIndustrySort(){
      getAction(this.urls.getToolsIndustrySort).then(res=>{
        if(res.success){
          res.result.forEach(item=>{
            let tempTypes = [];
            if(item.list){
              item.list.forEach(item2=>{
                let icons = [];
                let objTemp = {};
                objTemp.id = item2.id;
                objTemp.name = item2.labelName;
                if(item2.labelIconUrl){
                  icons = item2.labelIconUrl.split(",")
                }
                if(icons.length>1){
                  objTemp.icon = icons[0];
                  objTemp.iconActi = icons[1];
                }else if(icons.length == 1){
                  objTemp.icon = icons[0];
                  objTemp.iconActi = '';
                }else{
                  objTemp.icon = '';
                  objTemp.iconActi = '';
                }
                tempTypes.push(objTemp);
              });
            }
            this.industryMap.set(item.id,tempTypes);
            //初始化左侧分类栏 数据
            if(this.toolIndustryIndex){
              if(this.industryMap.get(this.toolIndustryIndex)){
                this.toolTypes = this.industryMap.get(this.toolIndustryIndex);
              }else{
                this.toolTypes = [];
              }
            }
          });
        }else{
          console.log(JSON.stringify(res.message));
        }
      }).catch(err=>{console.log(JSON.stringify(err));});
    },
    //点击左侧类型查询
    handleToolType({id}) {
      this.toolTypeIndex =  id;
      this.page = 1,
      this.sortId = id;
      this.toolsData =[];
      this.getToolsCollection();
    },
    //点击行业查询
    handleToolIndustry({id}) {
      this.toolIndustryIndex =id;
      if(this.industryMap.get(id)){
        this.toolTypes = this.industryMap.get(id);
      }else{
        this.toolTypes = [];
      }
      this.isExistLeftScroll()
      this.page = 1,
      this.industryId = id;
      this.sortId = ''
      this.toolsData =[];
      this.getToolsCollection();
    },
    //点击最新 最热 最多
    handleToolOrder({id}) {
      this.orderIndex = id
      this.params.newFlag = '';
      this.params.hotFlag = '';
      this.params.mostReadFlag = '';
      if('1'==id){
        //最新
        this.params.newFlag = '1';
      }else if('2'==id){
        //最热
        this.params.hotFlag = '1';
      }else if('3'==id){
        //最多
        this.params.mostReadFlag = '1';
      }else{
        //其他
      }
      this.toolsData =[];
      this.getToolsCollection();
    },
    //查看详情
    handleDetail(item) {
      this.$router.push({
        path: '/toolDetail',
        query: {
          toolId: item.id
        }
      })
    },

    /**
     * @Description: 滚动条的触底操作（距离底部50px时，开始加载下一页）
     * @Author: Niklaus
     * @Param:
     * @Return:
     * @Date: 2021-11-10 14:37:49
     */
    initScroll() {
      let scropHeight = document.documentElement.scropHeight || document.body.scrollHeight
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight
      if(scropHeight - scrollTop - clientHeight <= 0 &&this.loading==false
        &&this.toolsTotal>this.toolsData.length) {
        this.page++;
        this.getToolsCollection();
      }
    },

    /**
     * @Description: 判断左侧是否有滚动条
     * @Author: Niklaus
     * @Date: 2021-12-21 10:33:39
     */
    isExistLeftScroll() {
      this.$nextTick( () => {
        setTimeout(() => {
          let dom = document.getElementById('leftElRow')
          if(dom.scrollHeight - dom.clientHeight > 0) {
            this.showArrow = true
            this.hasScroll = true
          }
          else
            this.showArrow = false
          console.log(this.hasScroll)
          }, 100);
      })
    },

    /**
     * @Description: 左侧滚动条向下滚动
     * @Author: Niklaus
     * @Param:
     * @Return:
     * @Date: 2021-12-21 13:39:38
     */
    goScroll() {
      let dom = document.getElementById('leftElRow')
      if(dom.scrollHeight - dom.clientHeight - dom.scrollTop > 200) {
        dom.scrollTop =  dom.scrollTop + 200
      }else {
        dom.scrollTop = dom.scrollHeight - dom.clientHeight
        this.showArrow = false
      }
    },

    /**
     * @Description: 监听左侧栏滚动条 （有滚动条且未到底部 显示向下的箭头， 若已到达底部则隐藏箭头）
     * @Author: Niklaus
     * @Date: 2021-12-21 10:27:02
     */
    leftElRowScroll() {
      if(this.hasScroll) {
        let dom = document.getElementById('leftElRow')
        if(dom.scrollHeight - dom.clientHeight > dom.scrollTop) {
          this.showArrow = true
        }else {
          this.showArrow = false
        }
      }
    }
  }
}
</script>

<style scoped>
  .normal-svg {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    color: #8a8a8a;
  }
  .normal-svg.active {
    color: rgb(30, 135, 255);
  }
  .left-icon > i{
    display: inline-block;
    width: 1.2rem;
    height: 1.2rem;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(3px);
    margin-right: 5px;
  }
  .left-tool-type > p {
    padding: 1rem 0;
    padding-left: 1rem;
  }
  .tool-li-title .tool-title-content {
    width: calc(100% - 50px);
  }
  .user-img{
    border-radius: 50%;
  }
  .tool-pic {
    width: 100%;
    height: 12rem;
    object-fit: cover;
  }
  .tool-vertical-li > h3.el-ellipsis {
    padding: 15px 10px;
  }
  .left-row {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .tips-scroll {
    position: fixed;
    bottom: 88px;
    text-align: center;
    width: 12.5%;
    background-color: #74b9ff;
    cursor:pointer;
    transition: all .5s ease-in-out;
    opacity: 0;
    margin-left: -10px;
    padding: 5px 0;
  }

  .show-arrow {
    opacity: 1;
  }

  .tips-arrow {
    width: 1.5em;
    color: white;
  }
</style>